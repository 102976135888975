
import { defineComponent, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { setUserInfo } from "@/util/oauth";
import store from "@/store";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "login",
  setup() {
    let btnLoading = ref(false);
    let env = ref(
      process.env.NODE_ENV == "development" || process.env.NODE_ENV == "stage"
    );
    const activeName = ref(env.value ? "second" : "first");

    const login = (): void => {
      btnLoading.value = true;
      const redirect_uri =
        process.env.NODE_ENV == "development"
          ? "http://47.110.37.144:6004/api/oauth/auth" // 本地
          : process.env.NODE_ENV == "stage"
          ? "http://47.110.37.144:6004/api/oauth/auth" // 测试
          : "https://facemaster.meb.fit/api/oauth/auth"; // 正式
      const state = Math.round(Math.random() * 100000);
      let urlText = `https://gate.meierbei.com/uc-oa/oauth2/authorize?client_id=Vsz8nUF96Cf4zjWaQx872rtYd2xfCBAp&response_type=code&redirect_uri=${redirect_uri}&state=${state}&scope=ucapi_sso`;
      window.location.href = urlText;
      btnLoading.value = false;
    };

    let url = useRouter();
    const checkToken = async () => {
      const route = useRoute();
      if (route.query.cookie != null) {
        btnLoading.value = true;
        setUserInfo(route.query.cookie as string);
        const result = await store.dispatch("setUser", {
          userId: route.query.cookie,
        });
        await store.dispatch("setUserRole"); // 获取角色

        if (result == 200) {
          url.push("/home");
        } else {
          ElMessage.error("获取用户信息失败，请重新的登录");
        }

        btnLoading.value = false;
      } else {
        console.log("没有查询到cookie");
      }
    };

    onMounted(() => {
      checkToken();
    });

    let ploading = ref(false);
    let userName = ref("dp-admin");
    let password = ref("mebmeb");
    const passwordLogin = () => {
      if (password.value != "mebmeb") {
        ElMessage.error("密码错误，请重新输入");
        return;
      }
      ploading.value = true;
      let group = {
        userId: "5600fa75-15bf-4ec5-a72f-7acf48ef9d23",
        userName: "yangxin@meb.com",
        realName: "杨鑫",
        proGroupId: 2,
        proGroupName: "美学设计",
      };
      sessionStorage.setItem(
        "userInfo",
        "5600fa75-15bf-4ec5-a72f-7acf48ef9d23"
      );
      sessionStorage.setItem("userGroup", JSON.stringify(group));
      setTimeout(() => {
        ploading.value = false;
        url.push("/home");
      }, 1000);
    };
    return {
      login,
      btnLoading,
      activeName,
      userName,
      password,
      passwordLogin,
      ploading,
      env,
    };
  },
});
